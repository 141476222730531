<template>
  <div class="enigma-container relative">
    <overlay-navigation class="fixed z-20 w-full h-full" :hide-info="true" :hide-code="true" />
    <!-- TOP LEFT RETURN -->
    <div class="absolute z-40 return top-8 left-6 md:left-24 cursor-pointer" @click="goToHub()">
      <img src="../../assets/icons/left-arrow.svg" alt="">
    </div>
    <!-- TOP RIGHT SHARE -->
    <div class="absolute z-40 share top-10 right-6 md:right-24 cursor-pointer" @click="goToShare()">
      <img class="img-share" src="../../assets/icons/share.svg" alt="">
    </div>
    <!-- RIGHT CODE -->
    <div class="absolute z-40 -right-10 md:right-10 left-right-overlay transform rotate-90 top-1/2 -translate-y-1/2 code text-quinary cursor-pointer" @click="goToFind()">I FOUND A CODE</div>
    <div class="bottom-gradient fixed z-20 bottom-0"></div>
    <div class="enigma-text-wrapper fixed  z-30 left-0 top-0">
      <img class="enigma-text" src="../../assets/enigma-text/text.jpg" alt="">
    </div>
    <!-- <div class="text-container opacity-0 w-full h-full left-0 top-0">
      <div class="enigma-text-scroller relative">
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OverlayNavigation from '@/components/OverlayNavigation.vue'
import gsap from "gsap"
import { Draggable } from "gsap/Draggable";

export default {
  name: 'Enigma9',
  data () {
    return {
      wrapper: null,
      imgElt: null,
      xDown: null,
      yDown: null,
      manualScrolling: false,
      wheelScrollTimeout: null
    }
  },
  computed: {
    ...mapGetters({
      lastEnigmaId: "user/lastEnigmaId"
    })
  },
  components: {
    OverlayNavigation
  },
  destroyed () {
    document.querySelector('body').style.overflowY = 'auto'
    // window.removeEventListener('mousedown', this.handleClickStart)
    // window.removeEventListener('mouseup', this.handleClickEnd)
    window.removeEventListener('wheel', this.handleWheel)
    window.removeEventListener('DOMMouseScroll', this.handleWheel)
  },
  mounted () {
    // Trackings
    this.$gtmTracking.pageView()
    this.$mmTro.sendRtg()
    // Enable Draggable gsap plugin
    gsap.registerPlugin(Draggable);
    Draggable.create(".enigma-text", {
      type: "x,y",onClick: function() {
        console.log("clicked");
    },
      onDragEnd: function() {
        console.log('drag end')
      }
    })
    // Fade appearance
    let tlContainer = gsap.timeline()
    tlContainer.to('.text-container', {duration: 4, opacity: 1})
    // Desktop scroll management 
    if (window.innerWidth > 768) {
      // window.document.addEventListener('mousedown', this.handleClickStart, false)
      // window.document.addEventListener('mouseup', this.handleClickEnd, false)
      window.addEventListener("wheel", this.handleWheel);
      window.addEventListener("DOMMouseScroll", this.handleWheel);
    }
    // Scroll management
    this.$nextTick().then(() => {
      document.querySelector('body').style.overflowY = 'hidden'
    })
    this.wrapper = document.querySelector('.enigma-text-wrapper')

    let finalScrollX = 0
    const velocity = 0.5
    const update = () => {
      finalScrollX += (window.scrollX - finalScrollX) * velocity
      this.wrapper.style.transform = 'translateX(-' + Math.floor(window.scrollX) + 'px) translateY('+(Math.floor(window.scrollX/4.15)) +'px)'
      requestAnimationFrame(update)
    }
    update()
  },
  methods: {
    goToHub() {
      this.$router
        .push({ name: 'hub', params: { swiperIndex: this.lastEnigmaId || '1' } })
        .catch(console.log)
    },
    goToShare() {
      this.$gtmTracking.openSharePopin()
      this.$router
        .push({ name: 'share', params: { fromName: this.$route.name } })
        .catch(console.log)
    },
    goToFind() {
      this.$router
        .push({ name: 'find', params: { fromName: this.$route.name } })
        .catch(console.log)
    },
    handleWheel(evt) {
      clearTimeout(this.wheelScrollTimeout)
      const delta = Math.sign(event.deltaY);
      console.log('delta', delta)
      if (!this.manualScrolling) {
        this.manualScrolling = true
        if (delta === 1) { // scroll down
          window.scrollTo(window.scrollX + 500, 0)
        } else if (delta === -1) {
          window.scrollTo(window.scrollX - 500, 0)
        }
      }
      this.wheelScrollTimeout = setTimeout(() => {
        console.log('clear')
        this.manualScrolling = false
      }, 100)
    },
    handleClickStart(evt) {
      this.xDown = evt.clientX;                                      
      this.yDown = evt.clientY;                                      
    },
    handleClickEnd(evt) {
      if (!this.xDown || !this.yDown) {
        return;
      }
      const xDiff = this.xDown - evt.clientX;
      const yDiff = this.yDown - evt.clientY;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
        if (xDiff > 0) {
          /* left swipe */ 
          console.log('click swipe left')
          window.scrollTo(window.scrollX + 500, 0)
        } else {
          /* right swipe */
          console.log('click swipe right')
          window.scrollTo(window.scrollX - 500, 0)
        }                       
      } else {
        if (yDiff > 0) {
          /* up swipe */ 
          console.log('click swipe up')
        } else { 
          /* down swipe */
          console.log('click swipe down')
        }                                                                 
      }
      /* reset values */
      this.xDown = null;
      this.yDown = null;   
    }
  } 
}
</script>

<style lang="scss" scoped>
.enigma-container {
  width: 100%;
  height: 100vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .enigma-text-scroller {
    width: 5300px;
    height: 1100px;
  }
  .enigma-text-wrapper {
    transform: translateY(-550px);
    img.enigma-text {
      transform: rotateX(66deg) rotate(-33deg) translateY(-1850px);
      @media screen and (min-width: 768px) {
        transform: rotateX(66deg) rotate(-33deg) translateY(-1250px);
      }
      @media screen and (min-width: 1366px) {
        transform: rotateX(66deg) rotate(-33deg) translateY(-1350px);
      }
    }
  }
  .text-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .bottom-gradient {
    background: linear-gradient(to top, rgba(10,10,10,1) 20%,rgba(0,0,0,0) 100%);
    width: 100%;
    height: 100px;
  }
  img {
    max-width: none;
  }
  .left-right-overlay {
    top: 40%;
  }
  @media screen and (min-width: 768px) {
    .img-share {
      width: 28px;
      height: 26px;
    }
    .left-right-overlay {
      top: 50%;
    }
  }
}
</style>